<template>
	<div class="notice">
		<CCard class="card__container">
			<CCardHeader>
				<div class="text-center font-xl font-weight-bold"> Notifications </div>
			</CCardHeader>
			<div class="p-0">
				<ul>
					<li
						v-for="notification in notifications"
						:key="notification.id"
						class="notice__item border-bottom"
						:class="{
							read: notification.isRead,
							'font-weight-bold': !notification.isRead,
						}"
						@click="redirectNoti(notification)"
					>
						<div class="notice__wrapper w-100">
							<div
								class="notice__body"
								:class="{
									unread: !notification.isRead,
								}"
								v-html="notification.body"
							></div>
							<div :class="{ 'see-more': notification.data.orderTitle.length > 50 }">{{
								notification.data.orderTitle
							}}</div>
							<div class="notice__date">{{ convertUTCtoDateVN(notification.updatedAt) }}</div>
							<font-awesome-icon
								v-if="notification.isRead"
								:icon="['far', 'bookmark']"
								@click.stop="unreadNoti(notification)"
							/>
							<div v-else class="icon-blank"></div>
						</div>
					</li>
				</ul>

				<InfiniteLoading @infinite="getNoti">
					<div slot="no-more"></div>
					<div slot="no-results"></div>
				</InfiniteLoading>
			</div>
		</CCard>
	</div>
</template>
<script>
import { mapActions, mapMutations } from "vuex"
import { STATUS_FORM } from "../plugins/constants"
export default {
	name: "Notifications",
	components: {
		InfiniteLoading: () => import("vue-infinite-loading"),
	},
	data() {
		return {
			limit: 50,
			offset: 0,
			notifications: [],
			statusFormUpdate: [STATUS_FORM.UNREVIEW, STATUS_FORM.REQUESTED, STATUS_FORM.RESPONDED],
			stattusFormDetail: [STATUS_FORM.REVIEWED, STATUS_FORM.CANCELLED],
		}
	},
	beforeDestroy() {
		this.set(["notifications", []])
	},
	methods: {
		...mapActions(["getNotifications", "unreadNotify", "redirectNoti"]),
		...mapMutations(["set"]),
		async getNoti($state) {
			this.offset = this.notifications.length
			const params = {
				limit: this.limit,
				offset: this.offset,
				orderTitle: true,
			}
			const res = await this.getNotifications(params)
			if (res.length) {
				this.notifications = [...this.notifications, ...res]
				$state.loaded()
			} else {
				$state.complete()
			}
		},
		async unreadNoti(noti) {
			const res = await this.unreadNotify({ id: noti.id })
			this.notifications = res
		},
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.card__container {
	/* width: 70%; */
	margin: auto;
	max-height: calc(100vh - 186px);
	overflow: auto;
}
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.notice {
	&__content {
		width: calc(100% - 10px);
		margin-right: 24px;
	}

	&__item {
		padding: 0.75rem 1.25rem;
		cursor: pointer;

		&:active,
		&.read {
			background-color: #ebedef80;
		}

		&:hover {
			box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 #3c40434d,
				0 1px 3px 1px #3c404326;
			z-index: 2;
		}
	}

	&__body {
		white-space: break-spaces;
		font-weight: bolder;

		&.unread {
			font-weight: normal;
		}
	}

	&__wrapper {
		display: grid;
		grid-template-columns: 2fr 1.5fr 1fr min-content;
	}
}

.icon-blank {
	width: 12px;
	height: 12px;
}

.see-more {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 355px;
}
</style>

<style lang="scss">
b {
	font-weight: normal;
}

.unread b {
	font-weight: bolder;
}
</style>
